import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels, usePrefs, useRequestData } from 'wsm-common-data';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { g } from '../../global-constants';
import useFocusTrap from '../../hooks/modern/useFocusTrap';
import InputDisplay from './InputDisplay';
import VehicleCards from './VehicleCards';
import SearchLinks from './SearchLinks';
import RichContent from './richContent/RichContent';
import NoResults from './NoResults';
import RecentSearches from './RecentSearches';
import shouldShowRecentSearches from '../../util/modern/shouldShowRecentSearches';
import hasEmptyResults from '../../util/modern/hasEmptyResults';
import hasResults from '../../util/modern/hasResults';
import ContentColumnizer from './ContentColumnizer';
import useAriaLive from '../../hooks/modern/useAriaLive';

const FlyOut = ({
	isOpen,
	onClose,
	flyOutRef = React.createRef(),
	inputElementRef = React.createRef(),
	openButtonRef,
	closeModal,
	handleOnChangeInput,
	searchTerm,
	searchTermEmbedded,
	inventoryResults,
	handleLinkClick,
	addInfo,
	richContentToDisplay,
	inputRef,
	numberOfCharactersForFirstSearchNumber,
	pageResults,
	recentSearches,
	handleClearHistory,
	onRemove,
	onReorder,
	handleChipClick,
	handleSubmission,
	onClear,
	isMobile,
	screenWidth,
	isEmbeddedOverrideActive,
	noResultsLinks,
	noResultsRichContent
}) => {
	const labels = useLabels();
	const chipAriaLabel = labels.get('SEARCH');
	const removeAriaLabel = labels.get('REMOVE');
	const prefs = usePrefs();
	const [localIsOpen, setLocalIsOpen] = useState(false);
	const { windowId } = useRequestData();

	const { pageAlias } = useSelector((state) => state.widgetData);

	const { suggestions = undefined, vehicles = undefined } =
		inventoryResults || {};
	const isSearchTermLongEnough =
		searchTerm?.length >= numberOfCharactersForFirstSearchNumber;

	const hasResultsLocal = hasResults(
		pageResults,
		suggestions,
		vehicles,
		richContentToDisplay
	);

	const hasEmptyResultsLocal = hasEmptyResults(
		pageResults,
		suggestions,
		vehicles
	);

	const announceText = useAriaLive(
		isSearchTermLongEnough,
		hasResultsLocal,
		hasEmptyResultsLocal
	);

	const shouldRenderNoResults = !hasResultsLocal && hasEmptyResultsLocal;

	const hasRichContent = richContentToDisplay?.length > 0;

	const richContent = shouldRenderNoResults
		? noResultsRichContent
		: richContentToDisplay;

	useFocusTrap(
		[openButtonRef, flyOutRef],
		null,
		flyOutRef,
		null,
		openButtonRef,
		isOpen,
		closeModal
	);

	const isWideScreen = screenWidth > 768;

	const classNames = [
		'modern-search-flyout',
		'box',
		'pt-4 pb-4 pl-5 pr-5',
		localIsOpen && 'slide-left',
		!isMobile && !isWideScreen ? 'one-column' : isMobile && 'mobile',
		isWideScreen && 'two-columns'
	];

	const closeClassNames = [
		'btn-unstyled text-link-muted',
		'py-0',
		'px-0',
		'my-3',
		'border-neutral-0-alpha-3',
		'float-right'
	];

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setLocalIsOpen(true);
		}, 50);

		if (isOpen) {
			document.body.style.overflow = 'hidden';
			inputElementRef.current?.focus();
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [isOpen, inputElementRef]);

	const flyOutContent = (
		<>
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
			<div
				className="modern-search-modal clearfix"
				role="none"
				data-testid="modern-flyout-background"
				onClick={() => onClose(g.CLOSE_BUTTON)}
			/>
			<div
				className={setClassNames(classNames)}
				role="dialog"
				aria-labelledby={
					searchTerm
						? 'search-modal-headline'
						: 'recent-searches-heading'
				}
				ref={flyOutRef}
				tabIndex="-1"
				data-testid="modern-flyout"
			>
				<div aria-live="assertive" className="sr-only">
					{announceText}
				</div>
				<div className="pl-0 py-3 pr-0 clearfix">
					<h3
						className="ddc-heading-3 font-weight-bold text-capitalize float-left mt-3 ddc-font-size-xlarge"
						id="search-modal-headline"
					>
						{labels.get('SEARCH')}
					</h3>
					<button
						type="button"
						tabIndex="0"
						aria-label={labels.get('CLOSE_MODAL')}
						className={setClassNames(closeClassNames)}
						data-testid="flyout-close-button"
						onClick={() => onClose(g.CLOSE_BUTTON)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								onClose(g.CLOSE_BUTTON);
								setTimeout(() => {
									openButtonRef.current?.focus(); // Focus on the button after a delay
								}, 10);
							}
						}}
					>
						<i
							className="ddc-icon ddc-icon-remove2 icon-size-2"
							aria-hidden="true"
						/>
					</button>
				</div>

				<InputDisplay
					inputRef={inputRef}
					inputElementRef={inputElementRef}
					isOpen={isOpen}
					onClear={onClear}
					type={g.INPUT}
					data-testid="modern-input"
					handleSubmission={handleSubmission}
					searchTerm={searchTerm}
					searchTermEmbedded={searchTermEmbedded}
					handleOnChangeInput={handleOnChangeInput}
					isEmbeddedOverrideActive={isEmbeddedOverrideActive}
				/>
				<div className="mt-5 clearfix">
					{shouldShowRecentSearches(
						searchTerm,
						numberOfCharactersForFirstSearchNumber,
						hasResultsLocal,
						hasEmptyResultsLocal,
						recentSearches
					) && (
						<RecentSearches
							recentSearches={recentSearches}
							handleClearHistory={handleClearHistory}
							chipAriaLabel={chipAriaLabel}
							removeAriaLabel={removeAriaLabel}
							onRemove={onRemove}
							onReorder={onReorder}
							handleChipClick={handleChipClick}
						/>
					)}
					{isSearchTermLongEnough && (
						<div>
							<h3 id="results-heading" className="sr-only">
								{labels.get('SEARCH_RESULTS')}
							</h3>
							<div className="row">
								<ContentColumnizer
									inputWidth={screenWidth}
									vehicles={vehicles?.length > 0}
								>
									{vehicles?.length > 0 ? (
										<VehicleCards
											vehicles={vehicles}
											suggestion={
												suggestions?.length > 0
													? suggestions[0]
													: null
											}
											handleLinkClick={handleLinkClick}
											inputWidth={screenWidth}
										/>
									) : null}
									{suggestions?.length > 0 ? (
										<SearchLinks
											type="inventory"
											showResults={
												prefs.showInventoryResults
											}
											data={suggestions}
											iconClass="ddc-icon-single-vehicle"
											ariaLabel={labels.get(
												'INVENTORY_SEARCH_RESULTS'
											)}
											handleLinkClick={handleLinkClick}
										/>
									) : null}
									{pageResults?.length > 0 ? (
										<SearchLinks
											type="page"
											showResults={
												prefs.showPageSearchResults
											}
											data={pageResults}
											iconClass="ddc-icon-arrow-circle-right"
											ariaLabel={labels.get(
												'PAGE_SEARCH_RESULTS'
											)}
											handleLinkClick={handleLinkClick}
										/>
									) : null}
									{shouldRenderNoResults && (
										<>
											<NoResults />
											<SearchLinks
												type="no-results"
												showResults="true"
												data={noResultsLinks}
												ariaLabel={labels.get(
													'PAGE_SEARCH_RESULTS'
												)}
												handleLinkClick={
													handleLinkClick
												}
											/>
										</>
									)}
									{(hasRichContent ||
										shouldRenderNoResults) && (
										<RichContent
											addInfo={addInfo}
											richContentToDisplay={richContent}
											pageAlias={pageAlias}
											windowId={windowId}
											handleLinkClick={handleLinkClick}
										/>
									)}
								</ContentColumnizer>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);

	return createPortal(flyOutContent, document.body);
};

FlyOut.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	flyOutRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	inputElementRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	openButtonRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	closeModal: PropTypes.func,
	handleOnChangeInput: PropTypes.func,
	searchTerm: PropTypes.string,
	inventoryResults: PropTypes.shape({}),
	handleLinkClick: PropTypes.func,
	addInfo: PropTypes.shape(),
	richContentToDisplay: PropTypes.arrayOf(PropTypes.string),
	pageAlias: PropTypes.string,
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	numberOfCharactersForFirstSearchNumber: PropTypes.number,
	pageResults: PropTypes.arrayOf(PropTypes.shape({})),
	recentSearches: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			id: PropTypes.string
		})
	),
	handleClearHistory: PropTypes.func,
	onRemove: PropTypes.func,
	onReorder: PropTypes.func,
	handleChipClick: PropTypes.func,
	onClear: PropTypes.func,
	handleSubmission: PropTypes.func,
	isMobile: PropTypes.bool,
	screenWidth: PropTypes.number,
	noResultsLinks: PropTypes.arrayOf(PropTypes.shape({})),
	noResultsRichContent: PropTypes.arrayOf(PropTypes.string)
};

export default FlyOut;
