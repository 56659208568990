import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels, usePrefs } from 'wsm-common-data';
import { addNewRelicPageAction } from 'ddc-new-relic';
import RichContent from './richContent/RichContent';
import useFocusTrap from '../../hooks/modern/useFocusTrap';
import SearchLinks from './SearchLinks';
import NoResults from './NoResults';
import hasEmptyResults from '../../util/modern/hasEmptyResults';
import hasResults from '../../util/modern/hasResults';
import ContentColumnizer from './ContentColumnizer';
import Pagination from './Pagination';

const StaticEmbedded = ({
	dropDownRef = React.createRef(),
	inputRef,
	inputElementRef,
	isOpen,
	recentSearches,
	pageResults,
	searchTerm,
	numberOfCharactersForFirstSearchNumber,
	handleLinkClick,
	addInfo,
	richContentToDisplay,
	pageAlias,
	windowId,
	closeModal,
	noResultsLinks,
	noResultsRichContent
}) => {
	const { suggestions = undefined, vehicles = undefined } = {};
	const labels = useLabels();
	const prefs = usePrefs();
	const [currentPage, setCurrentPage] = useState(0);
	const isSearchTermLongEnough =
		searchTerm?.length >= numberOfCharactersForFirstSearchNumber;
	const fakePage = [[], []];
	const visibleLimit = 15;

	const hasResultsLocal = hasResults(
		pageResults,
		suggestions,
		vehicles,
		richContentToDisplay
	);

	const hasEmptyResultsLocal = hasEmptyResults(
		pageResults,
		suggestions,
		vehicles
	);

	const shouldRenderNoResults = !hasResultsLocal && hasEmptyResultsLocal;

	const hasRichContent = richContentToDisplay?.length > 0;

	const richContent = shouldRenderNoResults
		? noResultsRichContent
		: richContentToDisplay;

	useFocusTrap(
		[inputRef, dropDownRef],
		dropDownRef,
		null,
		inputElementRef,
		null,
		isOpen,
		closeModal
	);

	const classNames = ['modern-search-dropdown', 'p-4'];

	const pageCallback = (page) => {
		setCurrentPage(page);
		addNewRelicPageAction('ws-site-text-search pagination event', page);
	};

	if (
		!recentSearches?.length &&
		(!searchTerm ||
			!isSearchTermLongEnough ||
			(pageResults === undefined &&
				suggestions === undefined &&
				vehicles === undefined))
	) {
		return null;
	}

	if (pageResults?.length > visibleLimit) {
		pageResults.forEach((val, index) => {
			if (index < visibleLimit) {
				fakePage[0].push(val);
			} else {
				fakePage[1].push(val);
			}
		});
	}

	const searchLinkMarkup = () => {
		let markup;
		if (pageResults?.length > visibleLimit) {
			markup = (
				<div className="search-link-wrapper">
					<SearchLinks
						type="page"
						showResults={prefs.showPageSearchResults}
						data={fakePage[currentPage]}
						iconClass="ddc-icon-arrow-circle-right"
						headingLabel={labels.get('PAGE_SEARCH_RESULTS')}
						ariaLabel={labels.get('PAGE_SEARCH_RESULTS')}
						handleLinkClick={handleLinkClick}
						isEmbedded
					/>
					<div className="row">
						<div className="col-xs-12">
							<Pagination
								pageCallback={pageCallback}
								currentPage={currentPage}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			markup = (
				<SearchLinks
					type="page"
					showResults={prefs.showPageSearchResults}
					data={pageResults}
					iconClass="ddc-icon-arrow-circle-right"
					ariaLabel={labels.get('PAGE_SEARCH_RESULTS')}
					handleLinkClick={handleLinkClick}
					isEmbedded
				/>
			);
		}
		return markup;
	};

	const dropDownContent = (
		<div
			ref={dropDownRef}
			className={setClassNames(classNames)}
			data-testid="modern-input-dropdown"
			role="dialog"
			aria-labelledby={
				searchTerm ? 'results-heading' : 'recent-searches-heading'
			}
			aria-modal="true"
		>
			{isSearchTermLongEnough && (
				<div>
					<h2 id="results-heading" className="sr-only">
						{labels.get('SEARCH_RESULTS')}
					</h2>
					<ContentColumnizer
						inputWidth={inputRef?.current?.offsetWidth}
						vehicles={vehicles?.length > 0}
					>
						{pageResults?.length > 0 ? searchLinkMarkup() : null}
						{shouldRenderNoResults && (
							<>
								<NoResults />
								<SearchLinks
									type="no-results"
									showResults="true"
									data={noResultsLinks}
									ariaLabel={labels.get(
										'PAGE_SEARCH_RESULTS'
									)}
									handleLinkClick={handleLinkClick}
								/>
							</>
						)}
						{(hasRichContent || shouldRenderNoResults) && (
							<RichContent
								addInfo={addInfo}
								richContentToDisplay={richContent}
								pageAlias={pageAlias}
								windowId={windowId}
								handleLinkClick={handleLinkClick}
							/>
						)}
					</ContentColumnizer>
				</div>
			)}
		</div>
	);

	return dropDownContent;
};
StaticEmbedded.propTypes = {
	dropDownRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	inputElementRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	isOpen: PropTypes.bool.isRequired,
	recentSearches: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			id: PropTypes.string
		})
	),
	pageResults: PropTypes.arrayOf(PropTypes.shape({})),
	searchTerm: PropTypes.string,
	numberOfCharactersForFirstSearchNumber: PropTypes.number,
	handleLinkClick: PropTypes.func,
	addInfo: PropTypes.shape(),
	richContentToDisplay: PropTypes.arrayOf(PropTypes.string),
	pageAlias: PropTypes.string,
	windowId: PropTypes.string,
	closeModal: PropTypes.func,
	noResultsLinks: PropTypes.arrayOf(PropTypes.shape({})),
	noResultsRichContent: PropTypes.arrayOf(PropTypes.string)
};

export default StaticEmbedded;
